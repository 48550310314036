/* chatStyles.css */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 60%;
  margin: auto;
  overflow-y: auto;
}

.chat-history {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
}

.chat-input {
  display: flex;
  padding: 10px;
}

.chat-input input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}


/* Chat.css */
.main-page {
  /* display: flex;
  flex-direction: column;
  height: 100%; */
}

.chat-container {
  /* flex: 1;
  overflow-y: auto; */
  /* flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  overflow: hidden; */
  /* bottom: 70px;
  position: absolute;
  width: 100%;
  padding: 0 16px; */
}

.sent-message {
  /* text-align: right; */
}

.received-message {
  /* text-align: left; */
}

