/* App.css */
.app-container {
  background-color: #fff; /* Light gray background */
}

.input-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 16px;
  width: 100%;
}

/* formStyles.css */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
}

.logo-placeholder {
    height: 150px; /* Adjust size as needed */
    width: 150px; /* Adjust size as needed */
    background-color: #fff; /* Example background color, replace with actual logo */
    background-image: url('../../public/logo.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px; /* Optional: For placeholder text */
}
